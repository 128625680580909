import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "card"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "card card-bordered mb-5",
  id: "familyRef"
}
const _hoisted_7 = { class: "card-header ribbon mb-5" }
const _hoisted_8 = { class: "card-toolbar" }
const _hoisted_9 = { class: "card-footer" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_health_top = _resolveComponent("vc-health-top")!
  const _component_vc_health_middle = _resolveComponent("vc-health-middle")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_health_family = _resolveComponent("vc-health-family")!
  const _component_vc_quote_policy_details = _resolveComponent("vc-quote-policy-details")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "card" }, [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.health)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "card-title" }, "Generate Quote", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_vc_button_navigator, {
                text: "Back",
                value: "CustomerDetails",
                "class-name": "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
                "is-icon": "",
                params: { customer_id: _ctx.health.id }
              }, null, 8, ["params"])
            ])
          ]),
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            model: _ctx.health,
            "status-icon": "",
            rules: _ctx.rules,
            "scroll-to-error": "",
            id: "healthForm"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_vc_health_top, {
                  ref: "top",
                  onReValidate: _ctx.revalidate,
                  onTab: _ctx.checkform,
                  isDisabledMain: "",
                  "is-enable-name": ""
                }, null, 8, ["onReValidate", "onTab"]),
                _createVNode(_component_vc_health_middle, {
                  ref: "middle",
                  onReValidate: _ctx.revalidate,
                  "disabled-insurance-for": _ctx.health.insured_type_id_prev !== 0,
                  "disabled-insurance-type": true,
                  disabledGQInsuranceFor: ""
                }, null, 8, ["onReValidate", "disabled-insurance-for"]),
                (_ctx.health.insured_type_id >= 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "separator border-2 my-10" }, null, -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-title mb-5 mt-5" }, [
                            _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Policy Holder Details ")
                          ], -1)),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_el_button, {
                              type: "button",
                              class: "btn btn-lg btn-info w-10 h-auto",
                              onClick: _ctx.addNewMember
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" Add Member ")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ]),
                        _createVNode(_component_vc_health_family, {
                          ref: "familyRef",
                          isEdit: true,
                          onReValidate: _ctx.revalidate
                        }, null, 8, ["onReValidate"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _createVNode(_component_vc_quote_policy_details, {
                  onReValidate: _ctx.revalidate,
                  onUpdateExtraBenefit: _ctx.updateExtraBenefit,
                  showInsurancePlanError: _ctx.showInsurancePlanError,
                  onQuoteInsuranceType: _ctx.quoteInsuranceType
                }, null, 8, ["onReValidate", "onUpdateExtraBenefit", "showInsurancePlanError", "onQuoteInsuranceType"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_vc_button_navigator, {
                    value: "CustomerDetails",
                    params: { customer_id: _ctx.health.id }
                  }, null, 8, ["params"]),
                  _createVNode(_component_vc_button_save, {
                    loading: _ctx.clickLoading,
                    text: 'Generate & Download',
                    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.generateQuote(_ctx.ruleFormRef)))
                  }, null, 8, ["loading"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}